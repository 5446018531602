import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import {
  desktopBreakpoint,
  colors,
  zIndex,
  desktopVW,
  mobileVW,
} from '../../styles/index'
import { SettingsContext } from '../../contexts'
import CheckBox from '../shared/Checkbox'
import Icons from '../../utils/Icons'
// import LinkTool from '../shared/LinkType'

const Accordion = props => {
  const {
    filterIndex,
    title,
    displayTitle,
    content,
    roomFilter, 
    setRoomFilter,
    collectionFilter,
    setCollectionFilter,
    colorFilter,
    setColorFilter,
    priceFilter, 
    setPriceFilter,
    productAvailabelityFilter,
    setProductAvailabelityFilter,
  } = props

  const defaultHeight = '0px'

  const [open, toggle] = useState(false)

  const [contentHeight, setContentHeight] = useState(defaultHeight)

  const [priceFilterTemp, setPriceFilterTemp] = useState(priceFilter)

  const [filterPriceDragging, setFilterPriceDragging] = useState(false)

  const [isMobile, setIsMobile] = useState(
    typeof window != 'undefined' && window.innerWidth <= 1024 ? true : false,
  )

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
        return
      }
      setIsMobile(false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [, setY] = useSpring(() => ({
    y: 0,
    config: {
      tension: 330,
      friction: 30,
      clamp: true,
    },
  }))

  const ref = useRef()

  const expandSpring = useSpring({
    config: {
      tension: 130,
      friction: 13,
      clamp: true,
    },
    height: open ? `${contentHeight}px` : defaultHeight,
    opacity: open ? 1 : 0,
  })

  const spinSpring = useSpring({
    config: {
      tension: 130,
      friction: 14,
      clamp: true,
    },
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
    willChange: 'transform'
  })

  const handleClick = (item, state, setter) => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    if (!state.includes(item)) {
      setter([...state, item])
    } else if (state.includes(item)) {
      const index = state.indexOf(item)
      const temp = state
      if (index > -1) {
        temp.splice(index, 1)
        setter([...temp])
      }
    }
  }

  const handlePriceFilter = e=>{
    setPriceFilterTemp(Number(e.target.value))
    if(isMobile){
      
    }
  }

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    setTimeout(
      () =>
        ref.current &&
        setContentHeight(ref.current.getBoundingClientRect().height),
      200,
    )
    window.addEventListener(
      'resize',
      () =>
        ref.current &&
        setContentHeight(ref.current.getBoundingClientRect().height),
    )
    return window.removeEventListener(
      'resize',
      () =>
        ref.current &&
        setContentHeight(ref.current.getBoundingClientRect().height),
    )
  }, [])

  useEffect(() => {
    if (filterIndex === 0) {
      toggle(true)
    }
  }, [title])

  useEffect(() => {
    const inputElement = document.querySelector('[type="range"]');
    let isChanging = false;
    const setCSSProperty = () => {
      const percent =
        ((inputElement.value - inputElement.min) /
        (inputElement.max - inputElement.min)) *
        100;
      inputElement.style.setProperty("--webkitProgressPercent", `${percent}%`);
    }
    const handleMove = () => {
      if (!isChanging) return;
      setCSSProperty();
    };
    const handleUpAndLeave = () => isChanging = false;
    const handleDown = () => isChanging = true;
    inputElement.addEventListener("mousemove", handleMove);
    inputElement.addEventListener("mousedown", handleDown);
    inputElement.addEventListener("mouseup", handleUpAndLeave);
    inputElement.addEventListener("mouseleave", handleUpAndLeave);
    inputElement.addEventListener("click", setCSSProperty);
    setCSSProperty();
  }, [priceFilter])

  useEffect(() => {
    if(!filterPriceDragging){
      setPriceFilter(priceFilterTemp)
    }
  }, [filterPriceDragging])

  return (
    <>
    <Wrapper>
      <AccordionTitle
        onClick={() => toggle(!open)}
        type='button'
        aria-label={`show ${title} filters`}
      >
        {displayTitle}
        <animated.div
          style={{...spinSpring}}
        >
          >
        </animated.div>
      </AccordionTitle>
      <animated.div className='accordion' style={{ ...expandSpring }}>
        {
          title.toLowerCase().trim() === 'price'
          ?
          (
            <Content ref={ref}>
              <PriceSliderWrapper>
                <div className="PriceSliderLabel labelMin">Min: 0</div>
                  <input 
                    type="range" 
                    min={100}
                    max={30000000} 
                    value={priceFilterTemp} 
                    step={100}
                    onChange={e=>handlePriceFilter(e)}
                    onMouseDown={(e) => setFilterPriceDragging(true)}
                    onTouchStart={()=> setFilterPriceDragging(true)}
                    onMouseUp={(e) => setFilterPriceDragging(false)}
                    onTouchEnd={()=> setFilterPriceDragging(false)}
                    style={{width:'49%',marginLeft:'-12%'}}

                  />
                <div className="PriceSliderLabel labelMax">{`max: ${priceFilterTemp}`}</div>
              </PriceSliderWrapper>
              
            </Content>
          )
          :
          (
            <Content ref={ref}>
              {content.map(item => {
                switch (title.toLowerCase().trim()) {
                  case 'rooms':
                    return (
                      <FilterTitle
                        key={item}
                        onClick={() =>
                          handleClick(item, roomFilter, setRoomFilter)
                        }
                        className={
                          roomFilter && roomFilter.includes(item)
                            ? 'selected'
                            : ''
                        }
                      >
                        <CheckBox
                          checked={
                            roomFilter && roomFilter.includes(item)
                              ? true
                              : false
                          }
                        />
                        {item}
                      </FilterTitle>
                    )
                  case 'colors':
                    return (
                      <FilterTitle
                        key={item}
                        onClick={() =>
                          handleClick(item, colorFilter, setColorFilter)
                        }
                        className={
                          colorFilter && colorFilter.includes(item)
                            ? 'selected'
                            : ''
                        }
                      >
                        <CheckBox
                          checked={
                            colorFilter && colorFilter.includes(item)
                              ? true
                              : false
                          }
                        />
                        {item}
                      </FilterTitle>
                    )
                    case 'product-availabelity':
                      return (
                        <FilterTitle
                          key={item}
                          onClick={() =>
                            handleClick(item, productAvailabelityFilter, setProductAvailabelityFilter)
                          }
                          className={
                            productAvailabelityFilter && productAvailabelityFilter.includes(item)
                              ? 'selected'
                              : ''
                          }
                        >
                          <CheckBox
                            checked={
                              productAvailabelityFilter && productAvailabelityFilter.includes(item)
                                ? true
                                : false
                            }
                          />
                          {item}
                        </FilterTitle>
                      )
                  case 'collections':
                    return (
                      <FilterTitle
                        key={item}
                        onClick={() =>
                          handleClick(item, collectionFilter, setCollectionFilter)
                        }
                        className={
                          collectionFilter && collectionFilter.includes(item)
                            ? 'selected'
                            : ''
                        }
                      >
                        <CheckBox
                          checked={
                            collectionFilter && collectionFilter.includes(item)
                              ? true
                              : false
                          }
                        />
                        {item}
                      </FilterTitle>
                    )
                }
              })}
            </Content>
          )
        }
      </animated.div>
    </Wrapper>
    <Spacer />
    </>
  )
}

const Spacer = styled.div`
  width: 100%;
  height: 1px;
  background-color: #3C3B3B;

  ${desktopBreakpoint}{
    height: ${desktopVW(1)};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  //border-bottom: ${desktopVW(1)} solid rgba(255, 255, 255, 0.3);
  padding: 0 ${mobileVW(30)};

  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(61)};
    //border-bottom: ${desktopVW(1)} solid rgba(255, 255, 255, 0.3);
  }

  .wrapper {
    max-width: 500px;
  }

  .accordion {
    overflow: hidden;
    position: relative;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  font-size: ${mobileVW(15)};
  padding: ${mobileVW(50)} 0 ${mobileVW(30)} 0;
  border-top: ${desktopVW(1)} solid #3C3B3B;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  

  ${desktopBreakpoint} {
    padding: ${desktopVW(35)} 0 ${desktopVW(20)} 0;
    border-top: ${desktopVW(1)} solid #3C3B3B;
  }
`;

const FilterTitle = styled.h6`
  text-transform: capitalize;
  font-family: 'messinaMono';
  color: ${colors.white};
  font-size: ${mobileVW(15)};
  line-height: ${mobileVW(10)};
  display: flex;
  align-items: center;
  justify-content: left;
  text-transform: uppercase;
  margin-right: ${mobileVW(20)};
  margin-bottom: ${mobileVW(20)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(13)};
    line-height: ${desktopVW(3)};
    margin-right: ${desktopVW(20)};
    margin-bottom: ${desktopVW(20)};
    cursor: pointer;
  }
`;

const AccordionTitle = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${mobileVW(80)};
  width: 100%;
  text-transform: uppercase;
  font-family: 'messinaMono';
  color: ${colors.white};
  font-size: ${mobileVW(15)};

  ${desktopBreakpoint}{
    height: ${desktopVW(70)}; 
    font-size: ${desktopVW(13)};
    padding: 0;
  }
`;

const PriceSliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transform: translate(0%, -50%);
  height: ${mobileVW(15)};

  input[type="range"] {
    --thumbSize: ${mobileVW(15)};
    --trackSize: ${mobileVW(1)};
    --thumbBg: #fff;
    --trackBg: rgba(255, 255, 255, 0.1);
    --progressBg: #f2f2f2;
    --webkitProgressPercent: 0%;

    ${desktopBreakpoint}{
      --thumbSize: ${desktopVW(9)};
      --trackSize: ${desktopVW(1)};
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: var(--thumbSize);
    width: ${mobileVW(60)};
    margin: 0;
    padding: 0;
    height: 100%;
    transform: translateX(-10%);

    ${desktopBreakpoint}{
      width: ${desktopVW(190)};
      transform: translateX(-6%);
    }
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }

  input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }

  input[type="range"]::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: var(--trackSize);
    background-image: linear-gradient(
      90deg,
      var(--progressBg) var(--webkitProgressPercent),
      var(--trackBg) var(--webkitProgressPercent)
    );
    border-radius: calc(var(--trackSize) / 2);
  }

  input[type="range"]::-moz-range-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }

  input[type="range"]::-ms-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }


  .PriceSliderLabel{
    text-transform: uppercase;
    font-family: 'messinaMono';
    color: ${colors.white};
    font-size: ${mobileVW(15)};
    display: flex;
    align-items: center;
    justify-content: center;

    &.labelMin{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.labelMax{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    
    ${desktopBreakpoint}{
      font-size: ${desktopVW(13)};
      white-space: nowrap;
    }
  }

  ${desktopBreakpoint}{
    height: ${desktopVW(15)};
  }
`;

export default Accordion
