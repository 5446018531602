import { graphql } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AllProductsFilter from '../components/allProducts/AllProductsFilter';
import AllProductsGrid from '../components/allProducts/AllProductsGrid';
import SEO from '../components/shared/SEO';
import { SettingsContext } from '../contexts';
import { useGlobalComponentsQuery, useLocale } from '../hooks/index';

export interface allProductsProps {
  data: {
    contentfulPageAllProducts: {
      seoTitle: string;
      seoDescription: string;
      productCatalogue: any;
    };
  };
  pageContext: any;
}

const AllProducts = ({
  data: {
    contentfulPageAllProducts: { seoTitle, seoDescription, productCatalogue },
  },
  pageContext,
}: allProductsProps) => {
  const {
    categoryFilter,
    roomFilter,
    collectionFilter,
    colorFilter,
    priceFilter,
    productAvailabelityFilter,
    allProductShopify,
    resetFilters,
  }: {
    categoryFilter: any;
    roomFilter: any;
    colorFilter: any;
    collectionFilter: any;
    allProductShopify: any;
    resetFilters: () => void;
  } = useContext(SettingsContext);

  const { productCategory } = pageContext;
  const locale = useLocale();

  // When someone changes locale, reset filter
  useEffect(() => {
    // console.log("LOCALE CHANGED");
    // resetFilters()
  }, [locale]);

  const global = useGlobalComponentsQuery();

  const [allProducts] = useState(pageContext.allProducts);
  const [products, setProducts] = useState(pageContext.allProducts);
  const filterByPrice = array => {
    const filteredProdsByPrice = array.filter(product => {
      if (product.shopifyData.variants[0].price < priceFilter) {
        return product;
      }
    });
    setProducts(filteredProdsByPrice);
  };

  const filterByRoom = array => {
    if (roomFilter.length > 0) {
      const filteredProdsByRoom = array.filter(product => {
        const roomsPerProduct = product.room
          ? Object.values(product.room).find(mat => roomFilter.includes(mat))
          : false;
        if (roomsPerProduct) return product;
      });
      return filterByPrice(filteredProdsByRoom);
    }
    return filterByPrice(array);
  };

  const filterByColor = array => {
    if (colorFilter.length > 0) {
      const filteredProdsByColor = array.filter(product => colorFilter.includes(product.color));
      return filterByRoom(filteredProdsByColor);
    }
    return filterByRoom(array);
  };

  const filterByStock = array => {
    if (
      productAvailabelityFilter.length > 0 &&
      (productAvailabelityFilter[0] === 'In Stock' || productAvailabelityFilter[0] === 'Stock Ada')
    ) {
      const filteredProdsByStock = array?.filter(item => {
        const checkProduct = allProductShopify?.filter(a => a.node.handle === item.slug);
        if (checkProduct.length > 0) {
          const checkAvailable = checkProduct[0].node.variants.edges.filter(
            b => b.node.quantityAvailable > 0
          );
          if (checkAvailable.length > 0) {
            return item;
          }
        }
      });
      return filterByColor(filteredProdsByStock);
    }
    if (
      productAvailabelityFilter.length > 0 &&
      (productAvailabelityFilter[0] === 'Out of stock' ||
        productAvailabelityFilter[0] === 'Stock Tidak Ada')
    ) {
      const filteredProdsByStock = array?.filter(item => {
        const checkProduct = allProductShopify?.filter(a => a.node.handle === item.slug);
        if (checkProduct.length > 0) {
          const checkAvailable = checkProduct[0].node.variants.edges.filter(
            b => b.node.quantityAvailable > 0
          );
          if (checkAvailable.length === 0) {
            return item;
          }
        }
      });
      return filterByColor(filteredProdsByStock);
    }
    return filterByColor(array);
  };

  const filterByCollection = array => {
    if (collectionFilter.length > 0) {
      const filteredProdsByCollection = array.filter(product =>
        collectionFilter.includes(product.collection)
      );
      return filterByStock(filteredProdsByCollection);
    }
    return filterByStock(array);
  };

  const filterByCategory = array => {
    if (categoryFilter.length > 0) {
      const filteredProdsByCategory = array.filter(product =>
        categoryFilter.includes(product.productCategory.category)
      );
      return filterByCollection(filteredProdsByCategory);
    }
    return filterByCollection(array);
  };

  useEffect(() => {
    const prod = allProducts;
    filterByCategory(prod);
  }, [
    categoryFilter,
    roomFilter,
    collectionFilter,
    colorFilter,
    priceFilter,
    productAvailabelityFilter,
    productCategory,
  ]);

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} image={global?.seoImage.file.url} />
      {/* <BoxCatalogue>
    <PublitasContainer first={0} style={{marginTop:100}}/>
    </BoxCatalogue> */}
      <AllProductsGrid
        items={products}
        allProductsCategories={pageContext.allProductsCategories}
        pageProductCategory={productCategory}
      />
      <AllProductsFilter
        allProductsRooms={pageContext.allProductsRooms}
        allProductsCollections={pageContext.allProductsCollections}
        allProductsColors={pageContext.allProductsColors}
      />
    </>
  );
};
const BoxCatalogue = styled.section`
  margin-top: 120px;
  margin-bottom: 30px;
`;

export default AllProducts;

export const AllProductsPageQuery = graphql`
  query AllProductsPage($id: String!) {
    contentfulPageAllProducts(id: { eq: $id }) {
      seoTitle
      seoDescription
      productCatalogue {
        title
        data
      }
    }
  }
`;
